
.Menu{
    position: relative;
    padding:20px;
}

.m-item{
    position: absolute;
    top: 0;
    
    width: 10em;
    background-color: #ee7d31;
    box-shadow: 1px 2px 2px #ee7d31;

    padding: .3em;
    
    border-radius: 1em;

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 1.5em;
    color: #EDFFEC;
    cursor: pointer;

    transition: 1s;
}

.m-item:hover{
    border: 4px solid #FEFB00;
}

.m-logo{
    z-index: 1;
    border: 2px solid white;
}

.open-1{
    top: 1.8em;
    background-color: #9b5de5;
}
.open-2{
    top: 3.6em;
    background-color: #f15bb5;
}
.open-3{
    top: 5.4em;
    background-color: #00BBF9;
}
.open-4{
    top: 7.2em;
    background-color: #4ad637;
}
.open-5{
    top: 9em;
    background-color: #f7b731;
}
.open-6{
    top: 10.8em;
    background-color: #f30202;
}