.App{
  margin:20px;
  height: 100vh;
  background-color: #d9ccff;
  color:#473c65;
  font-family: "cooper-black-std", 'Courier New', Courier, monospace;
}

/* @media screen and (max-width: 768px){
  .App{
    margin: 40px;
    padding: auto;
    width: 400px;
    height: 100vh;
  }
} */